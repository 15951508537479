document.addEventListener('DOMContentLoaded', () => {
  /**
   * Функция переключения бургера и языков в мобильной версии
   */
  function toggleCountryAndBurger() {
    /**
     * Реализация переключения бургера и стран
     */
    const burger = document.querySelector('[data-burger]');
    const commonWrapper = document.querySelector('.js-common-wrapper');
    const countrySelector = document.querySelector('[data-country-selector]');
    const countryList = document.querySelector('[data-country-list]');
    const dataMenu = document.querySelector('[data-menu]');
    const dataInput = document.querySelector('[data-input]');
    const dataSearchBtn = document.querySelector('[data-search-btn]');

    try {
      burger.addEventListener('click', () => {
        burger.classList.toggle('active');
        commonWrapper.classList.toggle('active');
        document.body.classList.toggle('active');
        countryList.classList.remove('active');
        dataMenu.classList.remove('active');
      });

      countrySelector.addEventListener('click', () => {
        countryList.classList.toggle('active');
        dataMenu.classList.toggle('active');
      });

      dataSearchBtn.addEventListener('click', () => {
        dataInput.classList.toggle('active');
        dataSearchBtn.classList.toggle('active');
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  toggleCountryAndBurger();

  /**
   * Фунция открытия попапа (всех попапов). Берем
   */
  function openPopup() {
    const dataBurgerButtons = document.querySelectorAll('[data-popup]');
    const dataPopupCloseBtns = document.querySelectorAll('[data-popup-close="close"]');
    const fade = document.querySelectorAll('.js-popup__fade');

    if (!dataBurgerButtons.length) {
      return;
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < dataBurgerButtons.length; i++) {
      dataBurgerButtons[i].addEventListener('click', (e) => {
        e.preventDefault();
        const id = dataBurgerButtons[i].dataset.popup;
        const popup = document.getElementById(id);

        popup.classList.add('popup--is-open');
      });
    }

    fade.forEach(item => {
      item.addEventListener('click', () => {
        const parent = item.parentElement;

        parent.classList.remove('popup--is-open');
      });
    });

    dataPopupCloseBtns.forEach(item => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        const parent = item.closest('.popup');

        parent.classList.remove('popup--is-open');
      });
    });
  }

  openPopup();

  /**
   * Функция плавного скролла
   */
  function smoothScroll() {
    const scrollBtn = document.querySelectorAll('[data-scroll="smooth"]');

    for (let i = 0; i < scrollBtn.length; i++) {
      scrollBtn[i].addEventListener('click', (e) => {
        e.preventDefault();

        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
    }
  }

  smoothScroll();

  /**
   * Функция переключения табов на странице good-to-know
   */
  function changeTabs() {
    const tabs = document.querySelectorAll('[data-tabs]');
    const contentTabs = document.querySelectorAll('[data-tabs-name]');

    tabs.forEach(tab => {
      tab.addEventListener('click', () => {
        const selector = tab.dataset.tabs;

        if (selector === 'all') {
          contentTabs.forEach(t => t.classList.add('active'));
        } else {
          const currentTab = document.querySelector(`[data-tabs-name=${selector}]`);

          contentTabs.forEach(tabContent => tabContent.classList.remove('active'));
          currentTab.classList.add('active');
        }

        tabs.forEach(t => t.classList.remove('active'));
        tab.classList.add('active');
      });
    });
  }

  changeTabs();
});
