document.addEventListener('DOMContentLoaded', () => {
  const sliders = [
    {
      selector: '#main-slider',
      config: {
        speed: 400,
        loop: true,
        autoplay: {
          delay: 5000,
        },
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev',
        },
      },
    },
    {
      selector: '#breathe-slider',
      config: {
        speed: 400,
        slidesPerView: 'auto',
        spaceBetween: 15,
        loop: true,
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 100,
            allowSlidePrev: false,
            allowSlideNext: false,
          },
        },
      },
    },
    {
      selector: '#preparations-slider',
      config: {
        speed: 400,
        slidesPerView: 'auto',
        loop: true,
        autoplay: {
          delay: 5000,
        },
        navigation: {
          nextEl: '.prep-slider-wrapper__next',
          prevEl: '.prep-slider-wrapper__prev',
        },
        breakpoints: {
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
        },
      },
    },
    {
      selector: '#article-recommended-slider',
      config: {
        speed: 400,
        slidesPerView: 'auto',
        spaceBetween: 15,
        loop: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.article-swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.article-swiper-button-next',
          prevEl: '.article-swiper-button-prev',
        },
        breakpoints: {
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
            allowSlidePrev: false,
            allowSlideNext: false,
          },
        },
      },
    },
  ];

  function initSliders(selector, config) {
    const slider = document.querySelector(selector);

    if (slider) {
      const mySwiper = new Swiper(selector, config);
    }
  }

  sliders.forEach(({ selector, config }) => initSliders(selector, config));
});
